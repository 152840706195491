import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { init as analyticsInit } from './analytics';

/* tslint:disable:ordered-imports */
import './reset.css';
import './index.css';
import './modal.css';
/* tslint:enable:ordered-imports */

ReactDOM.render(<App />, document.getElementById('root'));

analyticsInit(document.body, window);
