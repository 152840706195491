import device from 'current-device';
import React, { ReactNode, useMemo } from 'react';

interface IDeviceDetectorProps {
  apple?: ReactNode;
  android?: ReactNode;
  other?: ReactNode;
}

enum DeviceType {
  APPLE,
  ANDROID,
  OTHER,
}

export const DeviceDetector: React.FC<IDeviceDetectorProps> = ({ apple, android, other }) => {
  const deviceType = useMemo(() => {
    if (device.ios() || device.ipad()) {
      return DeviceType.APPLE;
    }

    if (device.android()) {
      return DeviceType.ANDROID;
    }

    return DeviceType.OTHER;
  }, []);

  if (deviceType === DeviceType.APPLE) {
    return <>{apple}</>;
  }

  if (deviceType === DeviceType.ANDROID) {
    return <>{android}</>;
  }

  return <>{other}</>;
};
