import React from 'react';

import { imageSrc } from '../../utils/imageSrc';
import { Header } from '../CommonMobileSteps';
import {
  ImageWrapper,
  Path,
  PathImage,
  PathItem,
  GreenButton,
  StepHeader,
  TextBlock,
  Wrapper,
  GreyBlock,
  Title,
  VersionInfo,
  InfoWrapper,
  PulseLogoStyled,
  InfoBlock,
  Link,
  ButtonStyled,
} from '../CommonMobileSteps/styled';

import BoyWithPeacex1 from './steps/boy_with_peace.png';
import BoyWithPeacex2 from './steps/boy_with_peace@2x.png';
import BoyWithPeacex3 from './steps/boy_with_peace@3x.png';

const srcSetStep2 = [BoyWithPeacex1, BoyWithPeacex2, BoyWithPeacex3];

export const AndroidContent: React.FC = () => {
  return (
    <Wrapper>
      <Header platformName="Android" supportedFromVersion={'8.0'} />
      <Path>
        <PathItem>
          <StepHeader>1. Установите магазин корпоративных приложений Apps</StepHeader>
          <ButtonStyled href="https://sberapps.sber.ru">перейти к установке</ButtonStyled>
          <TextBlock>
            Убедитесь, что у вас установлены
            <Link href="https://www.gosuslugi.ru/crt"> сертификаты</Link> Минцифры - они потребуются
            для доступов к сервисам Банка.
          </TextBlock>
        </PathItem>
        <PathItem>
          <StepHeader>2. Установите Пульс из Apps</StepHeader>
          <GreyBlock>
            <InfoBlock>
              <PulseLogoStyled />
              <InfoWrapper>
                <Title>Пульс</Title>
                <VersionInfo>HR-платформа</VersionInfo>
              </InfoWrapper>
            </InfoBlock>
            <GreenButton>скачать</GreenButton>
          </GreyBlock>
          <ImageWrapper>
            <PathImage {...imageSrc(srcSetStep2)} alt="Поиск в SberStore" />
          </ImageWrapper>
        </PathItem>
      </Path>
    </Wrapper>
  );
};
