import { ColorThemes } from './styles';

export const saveTheme = (theme: ColorThemes) => {
  if (window.localStorage) {
    window.localStorage.setItem('platform:prelogin:colorTheme', theme);
  }
};

// const getSavedTheme = (): ColorThemes | null => {
//   if (!window.localStorage) {
//     return null;
//   }

//   const savedTheme = window.localStorage.getItem('platform:prelogin:colorTheme');

//   return (savedTheme as ColorThemes) || null;
// };

// const getSystemTheme = (): ColorThemes | null => {
//   if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
//     return ColorThemes.DARK_MODE;
//   }

//   if (window.matchMedia('(prefers-color-scheme: light)').matches) {
//     return ColorThemes.LIGHT_MODE;
//   }

//   return null;
// };

export const getDefaultTheme = (): ColorThemes => ColorThemes.LIGHT_MODE;
// getSavedTheme() || getSystemTheme() || ColorThemes.LIGHT_MODE;
