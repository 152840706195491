import React from 'react';

import { HeaderWrapper, PulseLogoStyled, Title, VersionInfo, InfoWrapper } from './styled';

export interface IHeaderProps {
  platformName: string;
  supportedFromVersion: string | number;
}

export const Header: React.FC<IHeaderProps> = ({ platformName, supportedFromVersion }) => (
  <HeaderWrapper>
    <PulseLogoStyled />
    <InfoWrapper>
      <Title>Пульс для {platformName}</Title>
      <VersionInfo>версия {supportedFromVersion} и новее</VersionInfo>
    </InfoWrapper>
  </HeaderWrapper>
);
