import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Close } from './close.svg';

interface IPopupWindowProps {
  onClose: () => void;
}

const CloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 28px;
  width: 21px;
  height: 21px;
  cursor: pointer;

  @media (max-width: 768px) {
    top: 24px;
    right: 24px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;

  height: 580px;
  width: 836px;

  @media (max-width: 768px) {
    padding: 32px;
    position: static;
    width: unset;
    height: unset;
    & > div > img {
      display: none;
    }
  }
`;

export const PopupWindow: React.FC<IPopupWindowProps> = ({ children, onClose }) => (
  <Wrapper>
    {children}
    <CloseButton data-testid="close-button" onClick={() => onClose()}>
      <Close />
    </CloseButton>
  </Wrapper>
);
