import React from 'react';
import styled from 'styled-components';

const Header = styled.h2`
  margin-bottom: 40px;
  color: ${({ theme }) => theme.text};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.41px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.text};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.08px;

  p {
    margin: 0 0 16px;
  }

  strong {
    font-weight: bold;
  }

  a {
    color: ${({ theme }) => theme.link};
    text-decoration: none;
  }
`;

const Nobr = styled.span`
  white-space: nowrap;
`;
export const CannotLoginIosInstruction: React.FC = () => (
  <div>
    <Header>не получается войти</Header>
    <Text>
      <p>
        <strong>для входа нужен логин в Сигме</strong>
      </p>
      <p>Это не адрес вашей почты в домене @sberbank.ru.</p>
      <p>
        В&nbsp;большинстве случаев логин в&nbsp;Сигме состоит из цифр — например, 17440441. Реже
        может быть буквенным — вроде <Nobr>petrov-aa</Nobr>.
      </p>
      <p>
        Если не можете войти, создайте обращение в&nbsp;Сбердруге{' '}
        <a href="https://sberfriend.sbrf.ru/sberfriend/#/interaction/new?template=Вход%20в%20HR%20платформу&extSystem=FRIEND">
          по&nbsp;этой ссылке
        </a>{' '}
        или по пути:
        <br />
        Каталог → Информационные технологии → Автоматизированные системы → Облачные сервисы HR →
        HR-платформа Пульс
      </p>
    </Text>
  </div>
);
