import React from 'react';

import { IErrorPageProperties } from './types';

const table: { [key: string]: IErrorPageProperties } = {
  NOT_IN_PILOT: {
    title: 'нет доступа',
    text: 'HR-платформа пока что открыта ограниченному числу пользователей. Ждите писем.',
  },
  DENY_EXTERNAL_NOT_MOBILE: {
    title: 'доступа нет',
    text:
      'Похоже, вы подключены к неизвестной и, возможно, незащищённой сети. Так войти не получится.',
  },
  RECRUITER_NOT_INTERNAL_NETWORK_SEGMENT: {
    title: 'что-то нет доступа',
    text: 'Подключитесь к офисной сети или рабочей VPN и попробуйте повторить.',
  },
  NOT_ALPHA_NETWORK_SEGMENT: {
    title: 'доступ ограничен',
    text: 'Чтобы исправить это, попробуйте войти через сеть Альфа.',
  },
  NOT_INTERNAL_NETWORK_SEGMENT: {
    title: 'был доступ, и нет его',
    text: 'Чтобы продолжить, подключитесь к офисной сети или рабочей VPN.',
  },
  NOT_EMM: {
    title: 'похоже, нет доступа',
    text: 'Чтобы продолжить, используйте мобильное приложение с защитой AirWatch.',
  },
  NOT_CITRIX: {
    title: 'доступно, но с условием',
    text: 'Войдите через Citrix, пожалуйста, и повторите. Извините, но без этого никак. ',
  },
  NOT_VPN: {
    title: 'доступ закрыт',
    text: 'Чтобы исправить это, подключите рабочую VPN.',
  },
};

export const getErrorPageProperties = (
  errorCode: string,
  errorData?: string
): IErrorPageProperties => {
  if (errorCode === 'NOT_AUTHORIZED') {
    return {
      title: 'ошибка доступа',
      text: (
        <>
          Передайте в службу поддержки ID-код этой ошибки: <br />
          {errorData}, <br />
          чтобы проблему устранили скорее.
        </>
      ),
    };
  }

  return table[errorCode];
};
