import React from 'react';
import styled from 'styled-components';

const Header = styled.h2`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.text};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -0.08px;
`;

const TextBlock = styled.p`
  color: ${({ theme }) => theme.text};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.08px;
`;

const Important = styled.strong`
  font-weight: bold;
`;

const OrdinaryTextBlock = styled(TextBlock)`
  margin-bottom: 16px;
  max-width: 565px;
`;

// const Link = styled.a`
//   color: ${({ theme }) => theme.text};
//   text-decoration: none;
//   font-weight: bold;
// `;

const BrowserLink = styled.a`
  color: ${({ theme }) => theme.link};
  text-decoration: none;
`;

export const CannotLoginInstruction: React.FC = () => (
  <div>
    <Header>Не&nbsp;получается войти</Header>
    <OrdinaryTextBlock>
      <Important>1. </Important>Для входа в&nbsp;Пульс нужен сертификат Сигмы, он&nbsp;называется
      как ваш логин и&nbsp;обычно состоит из&nbsp;цифр. Чтобы установить его, кликните
      по&nbsp;пункту меню &laquo;сертификаты&raquo; на&nbsp;главной странице HR-платформы Пульс.
    </OrdinaryTextBlock>
    <OrdinaryTextBlock>
      <Important>2. </Important>Иногда требуется обновить браузер, установленный на&nbsp;компьютере.
      <br />
      Или просто скачайте{' '}
      <BrowserLink href="https://sberusersoft.sigma.sbrf.ru/#search/%D0%AF%D0%BD%D0%B4%D0%B5%D0%BA%D1%81%20%D0%91%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80">
        Яндекс.Браузер
      </BrowserLink>
      &nbsp;&mdash; не&nbsp;прогадаете.
    </OrdinaryTextBlock>
    <OrdinaryTextBlock>
      <Important>3. </Important>Если ошибка сохраняется, создайте обращение{' '}
      <BrowserLink href="https://sberfriend.sbrf.ru/sberfriend/#/interaction/new?elementId=10773">
        в Сбердруге
      </BrowserLink>
      , выбрав &laquo;Вход&nbsp;в&nbsp;Пульс&raquo; или по шаблону: Сотруднику &rarr; Кадровое
      сопровождение, вход и профиль сотрудника в Пульсе
      &rarr;&nbsp;Вход&nbsp;в&nbsp;HR-платформу&nbsp;Пульс.
    </OrdinaryTextBlock>
    {/* <OrdinaryTextBlock>
      бесплатно с&nbsp;мобильного
      <br />
      <Link href="tel:88002006929">8 800 200-69-29</Link>
    </OrdinaryTextBlock>
    <OrdinaryTextBlock>
      с&nbsp;рабочего телефона
      <br />
      <Important>8 559 900 10</Important>
    </OrdinaryTextBlock> */}
  </div>
);
