import { Analytics, sendEvent } from '@hrplatform/analytics';

export const init = (target: HTMLElement, global: unknown) => {
  target.setAttribute('data-analytics-application', 'coreui');

  const analytics = new Analytics(target);

  (global as any)._HRP_ANALYTICS_SEND_EVENT = sendEvent;

  return analytics;
};
