import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
/**
 * редиректим сразу на платформу, если пришли на главную,
 * в остальных случаях оставляем кнопку [вход]
 */
export const RedirectOrEnter: React.FC = ({ children }) => {
  const { length } = useHistory();

  useEffect(() => {
    if (length <= 2) {
      window.location.pathname = '/platform/';
    }
  }, [length]);

  return <>{children}</>;
};
