import typography from '@pulse/typography';
import styled from 'styled-components';

import { ReactComponent as QrCode } from './hrp-qr-code.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PhoneImage = styled.img`
  flex-shrink: 0;
  display: block;
  display: block;
  width: 276px;
  height: 549px;
  margin-right: 40px;
`;

export const TextWrapper = styled.div`
  max-width: 320px;
`;

export const Header = styled.h2(({ theme }) => ({
  ...typography.h2Semibold,
  marginBottom: '32px',
  color: theme.text,
}));

export const TextBlock = styled.div(({ theme }) => ({
  ...typography.body1Regular,
  marginBottom: '32px',
  color: theme.text,
  '&:nth-child(2)': {
    marginBottom: '20px',
  },

  strong: {
    fontweight: 'bold',
  },
}));

export const Link = styled.a`
  color: ${({ theme }) => theme.link};
  text-decoration: none;
  font-weight: 500;
`;

export const QrCodeStyled = styled(QrCode)`
  width: 120px;
  height: 120px;
  fill: ${({ theme }) => theme.messageBackground};
`;
