import styled from 'styled-components';

export enum ColorThemes {
  LIGHT_MODE = 'LIGHT_MODE',
  DARK_MODE = 'DARK_MODE',
}

const lightModeTheme = {
  mode: ColorThemes.LIGHT_MODE,
  background: '#fff',
  mutedBackground: 'rgba(0, 0, 0, 0.15)',
  messageBackground: '#000000',
  messageText: '#ffffff',
  text: '#000',
  mutedText: '#828282',
  link: '#007aff',
  button: '#0066FF',
  buttonText: '#fff',
};

const darkModeTheme = {
  mode: ColorThemes.DARK_MODE,
  background: '#000',
  mutedBackground: 'rgba(255, 255, 255, 0.15)',
  messageBackground: '#ffffff',
  messageText: '#000000',
  text: '#fff',
  mutedText: '#828282',
  link: '#0a84ff',
  button: '#ffffff',
  buttonText: '#000000',
};

export const themes = {
  [ColorThemes.LIGHT_MODE]: lightModeTheme,
  [ColorThemes.DARK_MODE]: darkModeTheme,
};

export const AppContent = styled.div`
  background: ${({ theme }) => theme.background};
`;

export const getThemedPopupStyles = (theme: ColorThemes) => ({
  content: {
    background: themes[theme].background,
  },
  overlay: {
    background: themes[theme].background,
  },
});
