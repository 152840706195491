import React, { useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';

import { sendEvent, EventTypeEnum } from '@hrplatform/analytics';

import { ColorThemes } from '../../styles';
import { imageSrc } from '../../utils/imageSrc';

import dark1x from './dark.png';
import dark2x from './dark@2x.png';
import dark3x from './dark@3x.png';
import light1x from './light.png';
import light2x from './light@2x.png';
import light3x from './light@3x.png';
import { Header, Link, PhoneImage, TextBlock, TextWrapper, Wrapper, QrCodeStyled } from './styled';

export const MobileInstruction: React.FC = () => {
  const themeContext = useContext(ThemeContext);
  const isDarkMode = themeContext.mode === ColorThemes.DARK_MODE;

  useEffect(() => {
    sendEvent(EventTypeEnum.OPEN, {
      application: 'coreui',
      element: 'login_web_download_app',
      location: 'coreui-login_web',
    });
  }, []);

  const srcSet = isDarkMode ? [dark1x, dark2x, dark3x] : [light1x, light2x, light3x];

  return (
    <Wrapper>
      <PhoneImage {...imageSrc(srcSet)} alt="ассистент" />

      <TextWrapper>
        <Header>Одно приложение для&nbsp;целого Сбера</Header>
        <TextBlock>
          Пульс для <strong>iOS</strong> и&nbsp;<strong>Android</strong> помогает
          искать&nbsp;кандидатов, растить профессионалов и&nbsp;совершенствовать свои&nbsp;навыки.{' '}
          <br />
        </TextBlock>
        <TextBlock>
          Откройте <Link href="/">hr.sberbank.ru</Link> на&nbsp;телефоне или&nbsp;отсканируйте
          QR-код.
        </TextBlock>
        <TextBlock>
          <QrCodeStyled />
        </TextBlock>
      </TextWrapper>
    </Wrapper>
  );
};
