import typography from '@pulse/typography';
import styled from 'styled-components';

export const Wrapper = styled.div({
  maxWidth: '440px',
});

export const Header = styled.h2(({ theme }) => ({
  ...typography.h2Semibold,
  paddingBottom: '10px',
  color: theme.text,
}));

export const HeaderComment = styled.p(({ theme }) => ({
  ...typography.body1Regular,
  marginBottom: '24px',
  color: theme.text,
}));

export const InstallButton = styled.a(({ theme }) => ({
  ...typography.body1Semibold,
  display: 'inline-block',
  marginBottom: '24px',
  paddingY: '13px',
  paddingX: '28px',
  borderRadius: '5px',
  background: theme.button,
  color: theme.buttonText,
  textDecoration: 'none',
}));

export const TextBlock = styled.p(({ theme }) => ({
  ...typography.body1Regular,
  marginBottom: '16px',
  color: theme.mutedText,
}));

export const ActionList = styled.ol(({ theme }) => ({
  ...typography.body1Regular,
  color: theme.mutedText,
  counterReset: 'myCounter',
}));

export const ActionListItem = styled.li({
  listStyle: 'none',
  marginBottom: '16px',
  '&:before': {
    counterIncrement: 'myCounter',
    content: "counter(myCounter) '. '",
    fontWeight: 'bold',
  },
});

export const Link = styled.a(({ theme }) => ({
  color: theme.link,
  textDecoration: 'none',
}));
