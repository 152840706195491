import styled from 'styled-components';

export const ErrorModalWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;

  width: 492px;
  padding: 32px;

  @media (max-width: 768px) {
    position: static;
    width: unset;
    height: unset;
    & > div > img {
      display: none;
    }
  }
`;

export const ErrorModalContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`;

export const ErrorModalImageStyled = styled.img`
  display: block;
  width: 250px;
  height: 250px;
`;

export const ErrorModalTitleStyled = styled.h2`
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: SB Sans Display, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.01em;
`;

export const ErrorModalTextStyled = styled.p`
  font-family: SB Sans Display, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.018em;
  color: #939393;
  width: 100%;
`;

export const ErrorModalFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 24px;
  width: 100%;
`;

export const CloseButtonStyled = styled.div`
  display: block;
  flex-grow: 1;
  padding: 14px;
  border-radius: 8px;
  text-align: center;
  font-family: SB Sans Text, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.018em;
  background-color: #06f;
  color: #fff;
  cursor: pointer;
`;
