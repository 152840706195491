import React from 'react';

import {
  Wrapper,
  Header,
  HeaderComment,
  InstallButton,
  TextBlock,
  ActionList,
  ActionListItem,
  Link,
} from './styled';

export const CertificateInstruction: React.FC = () => (
  <Wrapper>
    <Header>Установите сертификаты безопасности</Header>
    <HeaderComment>Они нужны для доступа к&nbsp;сервисам Сбербанка.</HeaderComment>
    <InstallButton href="https://pking.sberbank.ru/">перейти к&nbsp;установке</InstallButton>
    <TextBlock>
      Вы&nbsp;перейдёте на&nbsp;другой сервис. Там всё делается в&nbsp;два счёта:
    </TextBlock>
    <ActionList>
      <ActionListItem>
        Скачиваете электронные сертификаты безопасности, вводите&nbsp;логин и&nbsp;пароль
        от&nbsp;Сигмы&nbsp;&mdash; вашей учётной записи, логин&nbsp;которой у&nbsp;большинства
        сотрудников состоит из&nbsp;цифр.
      </ActionListItem>
      <ActionListItem>Устанавливаете 3&nbsp;сертификата на&nbsp;компьютер.</ActionListItem>
    </ActionList>
    <TextBlock>
      Если не&nbsp;получается установить сертификат, создайте обращение&nbsp;
      <Link href="https://sberfriend.sbrf.ru/sberfriend/#/interaction/new?elementId=10773">
        в Сбердруге
      </Link>
      &nbsp;и&nbsp;выберите шаблон &laquo;Вопросы по&nbsp;Входу в&nbsp;HR платформу&raquo;
    </TextBlock>
  </Wrapper>
);
