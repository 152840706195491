import styled from 'styled-components';

import { ReactComponent as PulseLogo } from './assets/pulseLogo.svg';
import { ReactComponent as PulsePic } from './assets/pulsePic.svg';

export const Wrapper = styled.div`
  padding: 0 20px;
`;

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  padding-top: 34px;
  padding-bottom: 10px;
  align-items: center;
`;

export const VersionInfo = styled.p`
  color: #939393;
  font-family: 'SB Sans Text', SB Sans Display, Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: -0.018em;
  line-height: 16px;
  font-weight: 400;
  text-align: start;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.text};
  font-family: SB Sans Display, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.018em;
  line-height: 36px;
  margin-bottom: 2px;
  text-align: center;
  transition: color 200ms linear;
  font-family: 'SB Sans Text';
  line-height: 20px;
  text-align: start;
`;

export const PulseLogoStyled = styled(PulseLogo)`
  align-self: center;
  fill: ${({ theme }) => theme.messageBackground};
  height: 55px;
  width: 55px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
`;

export const Path = styled.ul`
  list-style: none;
  margin: 0;
`;

export const PathItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const StepHeader = styled.h3`
  color: ${({ theme }) => theme.text};
  font-family: SB Sans Text, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.018em;
  line-height: 20px;
  transition: color 200ms linear;
`;

export const TextBlock = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: 14px;
  letter-spacing: -0.018em;
  line-height: 20px;
  transition: color 200ms linear;
  font-family: SB Sans Text, Helvetica, Arial, sans-serif;
  margin-top: 12px;
  strong {
    font-weight: bold;
  }
`;

export const ButtonStyled = styled.a`
  align-self: center;
  background-color: #0066ff;
  border-radius: 8px;
  border: 1px solid #0066ff;
  color: ${({ theme }) => theme.buttonText};
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  letter-spacing: -0.018em;
  margin-bottom: 16px;
  margin-top: 32px;
  padding: 14px 28px;
  text-decoration: none;
  transition: background-color 200ms linear, color 200ms linear;
  font-family: SB Sans Text, Helvetica, Arial, sans-serif;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0;
`;

export const PathImage = styled.img`
  border-radius: 12px;
  margin-top: 16px;
`;

export const PulsePicStyled = styled(PulsePic)`
  align-self: center;
  margin-top: 16px;
  margin-bottom: 146px;
  fill: ${({ theme }) => theme.messageBackground};
  height: 161px;
  width: 203px;
`;

export const PointStyle = styled.li`
  list-style: disc;
  margin-left: 22px;
  font-family: SB Sans Text, Helvetica, Arial, sans-serif;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 12px;
`;

export const GreenButton = styled.button`
  padding: 8px 16px;
  color: #ffffff;
  background-color: #21a038;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  font-family: 'SB Sans Text';
  letter-spacing: -0.022em;
  height: 32px;
`;

export const GreyBlock = styled.div`
  margin-top: 16px;
  background-color: #fafafa;
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const Link = styled.a`
  text-decoration: none;
`;
