import React, { useState } from 'react';
import Modal from 'react-modal';
import { useParams, useHistory } from 'react-router-dom';

import { ErrorModal } from './ErrorModal';
import { getErrorPageProperties } from './errorMessages';

export const ErrorPage: React.FC = () => {
  const { errorCode, errorData } = useParams();
  const [modalIsOpen, setIsOpen] = useState(true);
  const history = useHistory();

  if (!errorCode) {
    return null;
  }

  const properties = getErrorPageProperties(errorCode, errorData);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setIsOpen(false);
          history.push('/');
        }}
        contentLabel="Модальное окно"
        closeTimeoutMS={200}
        className={{
          base: 'ErrorModal__Content',
          afterOpen: 'ErrorModal__Content--after-open',
          beforeClose: 'ErrorModal__Content--before-close',
        }}
        overlayClassName={{
          base: 'ErrorModal__Overlay',
          afterOpen: 'ErrorModal__Overlay--after-open',
          beforeClose: 'ErrorModal__Overlay--before-close',
        }}
        parentSelector={() => document.getElementById('modal-root') as HTMLElement}
      >
        <ErrorModal
          properties={properties}
          onClose={() => {
            setIsOpen(false);
            history.push('/');
          }}
        />
      </Modal>
    </div>
  );
};
