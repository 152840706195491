import React from 'react';

import { ColorThemes } from '../../styles';
import { getDefaultTheme } from '../../themes';
import { imageSrc } from '../../utils/imageSrc';

import LogoDarkMode from './logoDarkMode.jpg';
import LogoDarkMode2x from './logoDarkMode@2x.jpg';
import LogoDarkMode3x from './logoDarkMode@3x.jpg';
import LogoLightMode from './logoLightMode.jpg';
import LogoLightMode2x from './logoLightMode@2x.jpg';
import LogoLightMode3x from './logoLightMode@3x.jpg';

const srcSets = {
  [ColorThemes.LIGHT_MODE]: [LogoLightMode, LogoLightMode2x, LogoLightMode3x],
  [ColorThemes.DARK_MODE]: [LogoDarkMode, LogoDarkMode2x, LogoDarkMode3x],
};

export const Logo: React.FC = () => {
  return <img {...imageSrc(srcSets[getDefaultTheme()])} alt="Пульс — HR-платформа" />;
};
