import React, { useEffect } from 'react';
import styled from 'styled-components';

import { sendEvent, EventTypeEnum } from '@hrplatform/analytics';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
`;

const Header = styled.h1`
  margin-bottom: 24px;
  color: ${({ theme }) => theme.text};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.41px;
  transition: color 200ms linear;
`;

const Text = styled.div`
  margin-bottom: 56px;
  color: ${({ theme }) => theme.mutedText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.41px;
  transition: color 200ms linear;
`;

export const NoPilot: React.FC = () => {
  useEffect(() => {
    sendEvent(EventTypeEnum.OPEN, {
      application: 'coreui',
      element: 'login_not_in_pilot',
      location: 'coreui-login_web',
    });
  }, []);

  return (
    <Wrapper>
      <Header>Идёт закрытое тестирование</Header>
      <Text>HR-платформа пока что доступна ограниченному числу пользователей. Ждите писем.</Text>
    </Wrapper>
  );
};
