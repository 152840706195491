import React from 'react';

import { imageSrc } from '../../utils/imageSrc';

import Errorx1 from './images/error.png';
import Errorx2 from './images/error@2x.png';
import Errorx3 from './images/error@3x.png';
import {
  CloseButtonStyled,
  ErrorModalContentStyled,
  ErrorModalFooterStyled,
  ErrorModalImageStyled,
  ErrorModalTextStyled,
  ErrorModalTitleStyled,
  ErrorModalWrapperStyled,
} from './styled';
import { IErrorPageProperties } from './types';

interface IErrorModalProps {
  properties: IErrorPageProperties;
  onClose: () => void;
}

const srcSet = [Errorx1, Errorx2, Errorx3];

export const ErrorModal: React.FC<IErrorModalProps> = ({
  properties: { title, text },
  onClose,
}) => {
  return (
    <ErrorModalWrapperStyled data-testid="error-modal">
      <ErrorModalContentStyled>
        <ErrorModalImageStyled {...imageSrc(srcSet)} alt="Ошибка" />
        <ErrorModalTitleStyled>{title}</ErrorModalTitleStyled>
        <ErrorModalTextStyled>{text}</ErrorModalTextStyled>
      </ErrorModalContentStyled>
      <ErrorModalFooterStyled>
        <CloseButtonStyled onClick={onClose}>закрыть</CloseButtonStyled>
      </ErrorModalFooterStyled>
    </ErrorModalWrapperStyled>
  );
};
